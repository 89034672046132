import * as React from "react"
import Arrow from "./arrow"

function ActionBtn({ arrowClass, text, isWhite = false }) {
  return (
    <div className="action-btn__wrapper">
      <button
        className={`action-btn ${
          !isWhite
            ? "bg-maroon hover:bg-gradient-to-r hover:from-maroon hover:to-orange-red text-white action-btn"
            : "bg-white text-maroon"
        } `}
        aria-label="View More"
      >
        <Arrow className={arrowClass} color={isWhite ? "#BD023A" : "white"} />
      </button>
      <span
        className={`action-btn__text font-headline font-extrabold bg-clip-text ${
          !isWhite
            ? "text-transparent bg-gradient-to-r from-maroon to-orange-red"
            : "text-white"
        } uppercase`}
      >
        {text}
      </span>
    </div>
  )
}

export default ActionBtn
