import * as React from "react"

function Arrow({ className, color = "white" }) {
  return (
    <svg
      className={"arrow-icon " + className}
      style={{ margin: "auto" }}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5858 6L7.29289 1.70711L8.70711 0.292893L14.7071 6.29289L15.4142 7L14.7071 7.70711L8.70711 13.7071L7.29289 12.2929L11.5858 8H0V6H11.5858Z"
      />
    </svg>
  )
}

export default Arrow
