import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ActionBtn from "../components/action-btn"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"
import { rewriteSlug } from "../utils/rewriteSlug"
import SocialShare from "../components/social-share"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const ArticlePage = props => {
  const article = props.data.strapiArticle

  const seo = {
    metaTitle: article.title,
    metaDescription: article.Description.data.Description,
    shareImage: article.cover.url,
  }

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        siteTitle={article.title}
        data={props.data.strapiHeader}
        locale={article.locale}
        localizations={article.localizations.data[0]?.attributes ?? {}}
        parent={
          props.data.strapiBlogPage.localizations.data[0]?.attributes.slug
        }
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiBlogPage.slug}
      />
      <Layout as="article">
        <Seo
          lang={article.locale}
          description={article.Description.data.Description}
          title={article.title}
          seo={seo}
        />

        <section className="article-intro">
          <div className="article-intro__meta">
            <p className="article-intro__meta-category">
              {article.category.name}
            </p>
            /<p className="article-intro__meta-date">{article.publishedAt}</p>
          </div>
          <h1 className="article-intro__title">{article.title}</h1>
          <div className="article-intro__description">
            <ReactMarkdown>
              {article.Description.data.Description}
            </ReactMarkdown>
          </div>
          <div className="article-intro__author">
            <img
              className="article-intro__author-image"
              src={article.author.avatar.url}
              alt={article.author.name}
              loading="lazy"
            />
            <div className="article-intro__author-name">
              <span>{props.data.strapiGeneralTranslation.By}</span>
              <p>{article.author.name}</p>
            </div>
          </div>
        </section>
        <div className="article-cover">
          <img
            className="article-cover__image"
            src={article.cover.url}
            alt={article.title}
            loading="lazy"
          />
        </div>
        {article.blocks ? (
          <section className="article-content">
            {article.blocks.map((block, index) => {
              switch (block.strapi_component) {
                case "shared.rich-text":
                  return (
                    <ReactMarkdown key={index}>
                      {block.body.data.body}
                    </ReactMarkdown>
                  )
                case "shared.quote":
                  return <blockquote key={index}>{block.quoteBody}</blockquote>
                case "shared.media":
                  if (block.file.ext === ".mp4") {
                    return (
                      <div key={index} className="article-content__media">
                        <video src={block.file.url} controls />
                        {block.file.caption && <p>{block.file.caption}</p>}
                      </div>
                    )
                  } else {
                    return (
                      <div key={index} className="article-content__media">
                        <img
                          src={block.file.url}
                          alt={block.file.caption}
                          loading="lazy"
                        />
                        {block.file.caption && <p>{block.file.caption}</p>}
                      </div>
                    )
                  }
                default:
                  return null
              }
            })}
          </section>
        ) : null}
        <div className="article-footer">
          <Link to={"/" + rewriteSlug(props.data.strapiBlogPage.slug)}>
            <ActionBtn
              arrowClass={"arrow-left"}
              text={props.data.strapiGeneralTranslation.BackToBlog}
            />
          </Link>

          <SocialShare
            url={props.location.href}
            title={article.title}
            facebook={props.data.strapiGeneralSetting.SocialShareLinks.Facebook}
            twitter={props.data.strapiGeneralSetting.SocialShareLinks.Twitter}
            linkedIn={props.data.strapiGeneralSetting.SocialShareLinks.LinkedIn}
          />
        </div>
        <section className="article-contact-section">
          <div className="article-contact">
            <h3 className="article-contact__title">
              {props.data.strapiGeneralSetting.ContactSection.Headline}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  props.data.strapiGeneralSetting.ContactSection.TextPlainHTML
                    .data.TextPlainHTML,
              }}
            ></div>
          </div>
        </section>
      </Layout>

      <Footer
        contactSlug={props.data.strapiContact.slug}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        locale={article.locale}
        data={props.data.strapiFooter}
      />
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String, $locale: String) {
    strapiBlogPage(locale: { eq: $locale }) {
      slug
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    strapiArticle(locale: { eq: $locale }, slug: { eq: $slug }) {
      Description {
        data {
          Description
        }
      }
      author {
        avatar {
          url
        }
        name
      }
      blocks {
        ... on STRAPI__COMPONENT_SHARED_MEDIA {
          file {
            ext
            url
            caption
            alternativeText
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_SHARED_QUOTE {
          quoteBody: body
          strapi_component
        }
        ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
          body {
            data {
              body
            }
          }
          strapi_component
        }
      }
      category {
        name
        slug
      }
      cover {
        url
      }
      title
      slug
      locale
      publishedAt(formatString: "D MMMM YYYY")
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
    }

    strapiFooter(locale: { eq: $locale }) {
      ContactText
      Heading
      RightsClaim
      SocialHeading
      Text
      Secondary {
        Link
        Text
      }
      Location {
        Heading
        Text {
          data {
            Text
          }
        }
      }
      Footer {
        Link
        Text
      }
    }

    strapiHeader(locale: { eq: $locale }) {
      MobileContactButtonText
      MobileContactHeading
      MobileContactText
      Links {
        Link
        Text
      }
      ContactText
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

export default ArticlePage
